import React, { useEffect } from "react";
import mermaid from "mermaid";
import svgPanZoom from 'svg-pan-zoom';

export interface MermaidProps {
    text: string;
}

export const Mermaid: React.FC<MermaidProps> = ({ text }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        mermaid.mermaidAPI.initialize({
            startOnLoad: true,
            securityLevel: "loose",
            theme: "dark",
            logLevel: "info"
        });
    });

    useEffect(() => {
        if (ref.current && text !== "") {
            mermaid.mermaidAPI.render("preview", text, result => {
                if (ref.current === null) {return;}

                ref.current.innerHTML = result;

                setTimeout(() => {
                    const svg = ref.current?.querySelector("svg");
                    if (!svg) {return;}

                    svg.setAttribute('height', Math.max( svg.clientHeight, 200) + 'px')
                    // @ts-ignore
                    svg.style.maxWidth = null
                    svgPanZoom('#preview');
                }, 100)
            });
        }
    }, [text]);

    return <div key="preview" ref={ref} />;
};
