import React from 'react';
import {PanelProps} from '@grafana/data';
import {SimpleOptions} from 'types';
import {Mermaid, MermaidProps} from "./Mermaid";

interface Props extends PanelProps<SimpleOptions> {
}

export const SimplePanel: React.FC<Props> = ({options, data, width, height}) => {
    console.log(data);
    console.log("updating");

    // data.series[0].first.buffer

    if (data.series.length === 0 || data.series[0].fields.length === 0 || data.series[0].fields[0].values.toArray().length === 0) {
        return (<div>No data</div>);
    }

    const mermaidString = data.series[0].fields[0].values.toArray()[0];

    const mermaidProps: MermaidProps = {
        text: mermaidString
    };

    return (
        <div>
            <Mermaid {...mermaidProps} />
        </div>
    );
};
